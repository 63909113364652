import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ProfilePic from 'packs/common/ProfilePic';

export default class LeaderboardItem extends React.Component {
  isLeader() {
    return this.props.data.rank === 1;
  }

  render() {
    return (
      <div
        className={classNames(
          'leaderboard-ranking bg-primary md:flex flex-row justify-between items-center md:text-left text-center gap-10 md:rounded-full rounded-lg p-4 mb-4',
          { 'is-user text-white': this.props.isUser },
          { 'bg-opacity-25': !this.props.isUser },
          { 'border-black': this.isLeader() },
        )}
      >
        {this.props.data.rank && (
          <div className="ranking-right text-title">{this.props.data.rank}</div>
        )}
        <div className="ranking-body md:flex items-center gap-4">
          {this.props.data.image && (
            <ProfilePic image={this.props.data.image} size="square-8" />
          )}
          <div className="ranking-title">
            {this.props.data.link ? (
              <a
                href={this.props.data.link}
                className="block text-base leading-150"
              >
                {this.props.data.name}
              </a>
            ) : (
              <span className="block text-base leading-150">
                {this.props.data.name}
              </span>
            )}
            <span className="block text-sm leading-130">
              {this.props.data.secondary_name}
            </span>
          </div>
        </div>
        <div className="user-stats ml-auto">
          {this.props.data.stats &&
            this.props.data.stats.map(stat => (
              <small key={stat.unit} className="user-stat-item">
                <strong>{stat.value}</strong>
                &nbsp;{stat.unit}
              </small>
            ))}
        </div>
      </div>
    );
  }
}

LeaderboardItem.propTypes = {
  isUser: PropTypes.bool,
  data: PropTypes.object.isRequired,
};

LeaderboardItem.defaultProps = {
  isUser: false,
};
