import React from 'react';
import { createRoot } from 'react-dom/client';
import LeaderboardSelect from 'ui/components/Leaderboard/LeaderboardSelect';

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('leaderboard_ui');
  if (container) {
    const challengeId = container.attributes['data-challenge-id']?.value;
    const teamId = container.attributes['data-team-id']?.value;
    const root = createRoot(container);
    root.render(<LeaderboardSelect challengeId={challengeId} teamId={teamId} />);
  }
});
