import React from 'react';
import PropTypes from 'prop-types';
import { apiClient } from 'ui/utils';
import Error from 'packs/common/Error';
import Leaderboard from './Leaderboard';

export default class LeaderboardSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: '', error: null, leaderboards: [] };
    this.url = `challenges/${this.props.challengeId}/leaderboards?${
      this.props.teamId ? `team_id=${this.props.teamId}` : ''
    }`;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    apiClient.get(this.url).then((response) => {
      this.setState({ leaderboards: response.data });
    }).catch(() => {
      this.setState({ error: 'There was a problem loading the leaderboards.' });
    });
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  currentLeaderboard() {
    return this.state.leaderboards.find(l => l.title === this.state.value);
  }

  render() {
    if (this.state.error) {
      return <Error message={this.state.error} />;
    }
    return (
      <div>
        <div id="leaderboard-content" className="card-body">
          <div className="form-group mb-8 flex flex-col gap-2">
            <label htmlFor="leaderboard_select" className="text-form-label">
              Select a leaderboard
            </label>
            <span id="leaderboardNav">
              <select
                value={this.state.value}
                onChange={this.handleChange}
                className="form-control"
                id="leaderboard_select"
              >
                <option value="">-- Please select to view --</option>
                {this.state.leaderboards.map(leaderboard => (
                  <option key={leaderboard.title} value={leaderboard.title}>
                    {leaderboard.title}
                  </option>
                ))}
              </select>
            </span>
          </div>

          {this.state.value !== '' && (
            <Leaderboard
              id={this.state.value}
              challengeId={this.props.challengeId}
              teamId={this.props.teamId}
              key={this.state.value}
              groupBy={this.currentLeaderboard().groupBy}
              sortBy={this.currentLeaderboard().sortBy}
              scope={this.currentLeaderboard().scope}
            />
          )}
        </div>
      </div>
    );
  }
}

LeaderboardSelect.propTypes = {
  challengeId: PropTypes.string.isRequired,
  teamId: PropTypes.string,
};
